// HomePage.js for Quiznect.com website
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from './firebase';
import { sendEmailVerification, signInWithEmailAndPassword, createUserWithEmailAndPassword, OAuthProvider, signInWithPopup } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore'; // Import Firestore functions
import { firestore } from './firebase'; 
import { FaGoogle, FaApple } from 'react-icons/fa'; // Import Google and Apple icons
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase'; // Ensure analytics is exported from firebase.js

function HomePage() {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  //const [isLogin, setIsLogin] = useState(true); // Toggle between login and signup
  const navigate = useNavigate();
  const location = useLocation(); 

  // Initialize isLogin based on navigation state
  const [isLogin, setIsLogin] = useState(() => {
    if (location.state && location.state.mode === 'signup') {
      return false; // Set to Sign Up mode
    }
    return true; // Default to Log In mode
  });

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'page_view', {
        page_path: '/home',
        page_title: 'Home Page',
        page_location: window.location.href,
      });
    }
  }, [analytics]);

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    const storedPassword = localStorage.getItem('password');
    
    if (storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (user.emailVerified) {
        navigate('/landing'); // Navigate to LandingPage after successful login/signup if verified
      } else {
        alert('Please verify your email before proceeding.');
      }
    }
  }, [user, navigate]);

  // Listen for Enter key press and trigger login/signup
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleAuthAction(); // Call the login or signup function when Enter is pressed
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [email, password, isLogin]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleAuthAction = async () => {
    try {
      let userCredential;

      if (isLogin) {
        logEvent(analytics, 'login_attempt', { method: 'email' });
        // Log in the user
        userCredential = await signInWithEmailAndPassword(auth, email, password);
        setUser(userCredential.user);
        console.log('Login successful:', userCredential.user);
        logEvent(analytics, 'login_success', { method: 'email' });
        if (!userCredential.user.emailVerified) {
          alert('Please verify your email before accessing the app.');
          return;
        }

        // Check if the user has a screen name
        const userDoc = await getDoc(doc(firestore, 'users', userCredential.user.uid));
        if (userDoc.exists() && userDoc.data().screenName) {
          navigate('/landing'); // User has a screen name, navigate to landing page
          logEvent(analytics, 'navigate_to_landing');
        } else {
          navigate('/create-screen-name', { state: { uid: userCredential.user.uid, email } }); // No screen name, redirect to setup
          logEvent(analytics, 'navigate_to_create_screen_name');
        }

      } else {
        logEvent(analytics, 'signup_attempt', { method: 'email' });
        // Sign up the user
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
        setUser(userCredential.user);
        console.log('Signup successful:', userCredential.user);
        logEvent(analytics, 'signup_success', { method: 'email' });
        // Send email verification
        await sendEmailVerification(userCredential.user);
        //alert('A verification email has been sent to your email address. Please verify to continue.');

        // After successful signup and email verification sent
        navigate('/email-verification', { state: { uid: userCredential.user.uid, email } });
        logEvent(analytics, 'navigate_to_email-verification');
      }

      // Handle Remember Me functionality
      if (rememberMe) {
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
      }

    } catch (error) {
      console.error('Error during authentication:', error);
      if (isLogin) {
        // Log login failure
        logEvent(analytics, 'login_failure', { method: 'email', error_code: error.code });
      } else {
        // Log signup failure
        logEvent(analytics, 'signup_failure', { method: 'email', error_code: error.code });
      }
      alert(error.message);
    }
  };

  const toggleAuthMode = () => {
    setIsLogin((prev) => !prev);
  };

  const signInWithGoogle = async () => {
    const provider = new OAuthProvider('google.com');
    try {
      logEvent(analytics, 'social_login_attempt', { provider: 'google' });

      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      logEvent(analytics, 'social_login_success', { provider: 'google' });
      console.log('Google login successful:', result.user);
    } catch (error) {
      console.error('Error during Google login:', error);
      logEvent(analytics, 'social_login_failure', { provider: 'google', error_code: error.code });
      alert(error.message);

    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* HERO SECTION */}
      <section className="bg-gradient-to-r from-purple-700 to-[#841584] py-8 text-white text-center px-4">
        <div className="max-w-2xl mx-auto">
          <h1 className="text-2xl sm:text-3xl font-extrabold leading-tight">
            Create Quizzes to Learn Anything
          </h1>
          <p className="mt-4 text-md sm:text-sm max-w-md mx-auto">
            Guides and quiz materials on any topic. Start now!
          </p>
        </div>
      </section>

      {/* AUTH FORM SECTION */}
      <section className="flex-grow flex items-center justify-center py-4 px-4">
        <div className="w-full max-w-md bg-white rounded-lg shadow-md p-4 border border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800 mb-6 text-center">
            {isLogin ? 'Log In' : 'Sign Up'}
          </h2>

          <div className="mb-4">
            <label htmlFor="email-input" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              id="email-input"
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Type your email..."
              className="mt-1 w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-purple-400"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="password-input" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              id="password-input"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Type your password..."
              className="mt-1 w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-purple-400"
            />
          </div>

          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              id="remember-me"
              checked={rememberMe}
              onChange={handleRememberMeChange}
              className="mr-2 h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
            />
            <label htmlFor="remember-me" className="text-sm text-gray-600">
              Remember Me
            </label>
          </div>

          <button
            onClick={handleAuthAction}
            className="w-full bg-purple-600 hover:bg-purple-700 text-white py-2 rounded-md font-semibold transition-colors"
          >
            {isLogin ? 'Log In' : 'Sign Up'}
          </button>

          <button
            onClick={toggleAuthMode}
            className="w-full mt-3 bg-gray-100 hover:bg-gray-200 text-gray-700 py-2 rounded-md text-sm transition-colors"
          >
            {isLogin ? 'Create an Account' : 'Already have an account? Log In'}
          </button>

          {isLogin && (
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/reset-password')}
                className="text-purple-600 hover:text-purple-800 text-sm underline"
              >
                Forgot Password?
              </button>
            </div>
          )}

          <div className="flex items-center my-4">
            <hr className="flex-grow border-gray-300" />
            <span className="px-2 text-sm text-gray-500">OR</span>
            <hr className="flex-grow border-gray-300" />
          </div>

          <button
            onClick={signInWithGoogle}
            className="w-full bg-red-500 hover:bg-red-600 text-white py-2 rounded-md flex items-center justify-center transition-colors"
          >
            <FaGoogle className="w-5 h-5 mr-2" />
            Continue with Google
          </button>

          <p className="text-xs text-center text-gray-500 mt-2">
            If you already have an account, we'll log you in.
          </p>
        </div>
      </section>

      
      {/* INTRODUCTION & VALUE PROPOSITION */}
      <section className="py-6 px-4 bg-gray-50">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-xl sm:text-2xl font-bold text-gray-800">
            What is Quiznect?
          </h2>
          <p className="mt-4 text-sm sm:text-base text-gray-600 leading-relaxed">
            Quiznect empowers you to create hundreds of customized quizzes on any topic—from 
            programming languages to interview prep. Harness the power of AI to quickly build 
            personalized study plans and interactive quizzes, so you can learn more efficiently 
            and feel confident in your mastery.
          </p>
        </div>
      </section>

      {/* HOW IT WORKS */}
      <section className="py-6 px-4">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-xl sm:text-2xl font-bold text-gray-800 text-center">
            How It Works
          </h2>
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-3 gap-6">
            {/* Step 1 */}
            <div className="flex flex-col items-center text-center p-4">
              <div className="rounded-full bg-purple-100 p-4 mb-3">
                <span className="text-purple-600 font-bold">1</span>
              </div>
              <h3 className="font-semibold text-gray-800">Enter Your Learning Goal</h3>
              <p className="text-sm text-gray-600 mt-2">
                Simply specify what you want to learn or the job role &amp; company you’re preparing for.
              </p>
            </div>

            {/* Step 2 */}
            <div className="flex flex-col items-center text-center p-4">
              <div className="rounded-full bg-purple-100 p-4 mb-3">
                <span className="text-purple-600 font-bold">2</span>
              </div>
              <h3 className="font-semibold text-gray-800">Get Custom Quizzes</h3>
              <p className="text-sm text-gray-600 mt-2">
                Our AI creates personalized quizzes and a learning plan tailored just for you.
              </p>
            </div>

            {/* Step 3 */}
            <div className="flex flex-col items-center text-center p-4">
              <div className="rounded-full bg-purple-100 p-4 mb-3">
                <span className="text-purple-600 font-bold">3</span>
              </div>
              <h3 className="font-semibold text-gray-800">Learn &amp; Improve</h3>
              <p className="text-sm text-gray-600 mt-2">
                Practice quizzes, review your progress, and level up your skills or interview performance.
              </p>
            </div>
          </div>
        </div>
      </section>

     
      {/* FEATURE GALLERY */}
      <section className="py-1">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-gray-800 mb-8 text-center">
            Explore What You Can Learn
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: 'I want to learn Python programming.',
                description: 'Master Python from basics to advanced concepts.',
                link: 'https://quiznect.com/quizpage?id=20241215211049158025',
              },
              {
                title: 'I want to improve my public speaking skills.',
                description:
                  'Boost your public speaking confidence with tailored exercises.',
                link: 'https://quiznect.com/quizpage?id=20241215223754504374',
              },
              {
                title: 'I want to master data analysis with Excel.',
                description:
                  'Learn how to perform complex data analysis with interactive quizzes.',
                link: 'https://quiznect.com/quizpage?id=20241216014833657703',
              },
              {
                title: 'I want to create effective marketing campaigns.',
                description:
                  'Discover strategies and quizzes to develop successful campaigns.',
                link: 'https://quiznect.com/quizpage?id=20241203010510919642',
              },
              {
                title: 'I want to prepare for a job interview in tech.',
                description: 'Get quizzes and prep materials tailored to tech roles.',
                link: 'https://quiznect.com/quizpage?id=20241216023213901493',
              },
              {
                title: 'I want to learn wilderness survival.',
                description: 'Test if you know what it takes to survive in the wild.',
                link: 'https://quiznect.com/quizpage?id=20241216034723787921',
              },
            ].map((item, index) => (
              <a
                key={index}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="block p-6 rounded-lg shadow bg-[#841584] hover:shadow-lg transition-shadow duration-300 ease-in-out text-white"
              >
                <h3 className="text-lg font-bold mb-2">{item.title}</h3>
                <p className="text-sm">{item.description}</p>
              </a>
            ))}
          </div>
        </div>
      </section>

       {/* FEATURES & BENEFITS */}
       <section className="py-6 px-4 bg-gray-50">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-xl sm:text-2xl font-bold text-gray-800 text-center">
            Features &amp; Benefits
          </h2>
          <ul className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-4 list-inside list-disc text-sm text-gray-700">
            <li><strong>iOS & Android Apps:</strong> Take your quizzes on your mobile device.</li>
            <li><strong>Custom Quizzes:</strong> Generate topic-specific quizzes in minutes.</li>
            <li><strong>Personalized Learning:</strong> Get a study plan based on your goals.</li>
            <li><strong>Interview Prep:</strong> Targeted questions for specific job roles &amp; companies.</li>
            <li><strong>Time Saver:</strong> Spend less time searching and more time mastering content.</li>
            <li><strong>Reduce Anxiety:</strong> Feel confident walking into any exam or interview.</li>
          </ul>
        </div>
      </section>

      
      {/* APP DOWNLOAD + SOCIALS SECTION */}
      <section className="py-8 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col items-center justify-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6 text-center">
            <a
              href="https://apps.apple.com/us/app/quiznect/id6503987749"
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 bg-[#841584] text-white rounded-md hover:bg-purple-700 transition-colors font-semibold flex items-center"
            >
              <img
                src="/favicon-96x96.png"
                alt="Quiznect Icon"
                className="w-6 h-6 mr-2"
              />
              iOS App
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.sevenhillsstudios.quiznect"
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 bg-[#841584] text-white rounded-md hover:bg-purple-700 transition-colors font-semibold flex items-center"
            >
              <img
                src="/favicon-96x96.png"
                alt="Quiznect Icon"
                className="w-6 h-6 mr-2"
              />
              Android App
            </a>
            <a
              href="https://www.youtube.com/@Quiznect-o8n"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#841584] text-white p-3 rounded-lg text-lg font-bold inline-flex items-center hover:bg-red-700 transition-colors duration-300 ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="w-6 h-6 mr-3"
                viewBox="0 0 24 24"
              >
                <path d="M19.615 3.184A4.59 4.59 0 0 0 16.545 2H7.455c-1.202 0-2.353.44-3.212 1.184C3.268 4.019 2.928 5.12 2.817 6.416 2.673 8.141 2.673 10.224 2.673 12c0 1.776 0 3.86.144 5.584.111 1.297.451 2.398 1.426 3.232.859.745 2.01 1.184 3.212 1.184h9.09c1.202 0 2.353-.44 3.211-1.184.975-.834 1.315-1.935 1.427-3.232.143-1.724.143-3.808.143-5.584 0-1.776 0-3.859-.144-5.584-.111-1.297-.451-2.398-1.426-3.232ZM10.455 14.273V9.727L14.727 12 10.455 14.273Z" />
              </svg>
              YouTube Channel
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61569921943333"
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 bg-[#841584] text-white rounded-md hover:bg-purple-700 transition-colors font-semibold flex items-center"
            >
              {/* Facebook icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="w-5 h-5 mr-2"
                viewBox="0 0 24 24"
              >
                <path d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24H12.82V14.708h-3.156v-3.626h3.156V8.413c0-3.12 1.905-4.816 4.686-4.816 1.33 0 2.472.099 2.806.143v3.25l-1.926.001c-1.51 0-1.802.717-1.802 1.768v2.318h3.601l-.469 3.626h-3.132V24h6.139C23.407 24 24 23.407 24 22.675V1.325C24 .593 23.407 0 22.675 0Z" />
              </svg>
              Facebook
            </a>
            <a
              href="https://open.spotify.com/show/6GElpdQfkRi0YouLvg342c?si=ER4OaIJ9QvuvXO8L4gNEWQ"
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 bg-[#841584] text-white rounded-md hover:bg-purple-700 transition-colors font-semibold flex items-center"
            >
              {/* Spotify icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="w-5 h-5 mr-2"
                viewBox="0 0 24 24"
              >
                <path d="M12 0C5.373 0 0 5.372 0 12c0 6.628 5.373 12 12 12s12-5.372 12-12c0-6.628-5.373-12-12-12Zm5.205 17.439c-.24.36-.681.472-1.047.271-2.865-1.65-6.472-2.015-10.727-1.082-.413.093-.837-.15-.93-.565-.093-.413.15-.836.564-.93 4.706-1.083 8.75-.658 11.949 1.317.36.209.472.65.271 1.047Zm1.322-3.052c-.298.467-.926.616-1.414.33-3.281-1.902-8.272-2.459-12.117-1.32-.53.15-1.08-.165-1.233-.681-.15-.53.165-1.08.681-1.233 4.296-1.238 9.83-.609 13.553 1.545.467.271.63.887.33 1.414Zm.15-3.353c-.359.591-1.14.774-1.73.413-3.77-2.273-9.395-2.774-13.44-1.49-.682.21-1.438-.195-1.644-.877-.209-.682.195-1.438.877-1.644 4.687-1.393 10.83-.833 15.026 1.73.591.359.774 1.14.412 1.73Z" />
              </svg>
              Spotify
            </a>
          </div>
        </div>
      </section>

      {/* FOOTER / DISCLAIMER */}
      <footer className="py-6 bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 flex flex-col items-center">
          <button
            onClick={() => navigate('/disclaimer')}
            className="text-sm text-purple-700 hover:underline mb-2"
          >
            Disclaimer and Privacy Policy
          </button>
          <p className="text-xs text-gray-500">
            &copy; {new Date().getFullYear()} Quiznect. All Rights Reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;




      {/* Apple Login  - Disabled for now: */}
        {/*

            const signInWithApple = async () => {
    const provider = new OAuthProvider('apple.com');
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      console.log('Apple login successful:', result.user);
    } catch (error) {
      console.error('Error during Apple login:', error);
    }
  };


        <button
          onClick={signInWithApple}
          className="mt-2 w-full bg-white text-black p-3 rounded-lg flex justify-center items-center auth-buttons"
        >
          <FaApple className="w-6 h-6 mr-2" />
          Continue with Apple
        </button>
          */}