// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAuxi7PShk24rF9L5aqvZxJXTA_cMkZsVU",
  authDomain: "quiznect.firebaseapp.com",
  projectId: "quiznect",
  storageBucket: "quiznect.appspot.com",
  messagingSenderId: "473044494109",
  appId: "1:473044494109:web:c166d5611f5d15079963f4",
  measurementId: "G-QZS29G0CFR"
};

export const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
let analyticsInit;
if (typeof window !== 'undefined' && !window.analyticsInitialized) {
  analyticsInit = getAnalytics(app);
}

export const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Now the auth state will persist across tabs and browser restarts
  })
  .catch((error) => {
    console.error('Error setting auth persistence:', error);
  });

  
export const firestore = getFirestore(app);
export const analytics = analyticsInit;
export const functions = getFunctions(app, 'us-central1');

